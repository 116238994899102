<template>
    <section v-if="filteredOptions.length">
        <b-dropdown
            no-caret
            variant="link"
            class="printable-button"
            v-b-tooltip.hover title="Imprimir documentos"
        >
            <template #button-content>
                <Printer class="icon"/>
            </template>
            <b-dropdown-item v-for="(option, index) in filteredOptions" :key="index" variant="link" @click="dispatchAction(option)">
                <span class="item-li">{{ option.label }}</span>
            </b-dropdown-item>
        </b-dropdown>
        
        <StatementOfAwarenessModal :form="form" />
    </section>
</template>
<script>
import { saveAs } from 'file-saver'
import { getCurrentClinic } from '@/utils/localStorageManager'
import {  showFeature } from '@/utils/permissionsHelp';

export default {
    name: 'SelectPrintableFile',
    props: {
        form: Object,
        patientIdSettingsActive: Boolean,
    },
    components: {
        Printer: () => import('@/assets/icons/print.svg'),
        StatementOfAwarenessModal: () => import('@/components/General/SelectPrintableFile/StatementOfAwarenessModal'),
    },
    computed: {
        filteredOptions() {
            return this.options.filter(option => option.active)
        },
    },
    async mounted() {
        this.getAppointmentPrintables()
    },
    data() {
        return {
            loading: false,
            clinic: getCurrentClinic(),
            options: [],
        }
    },
    methods: {
        async getAppointmentPrintables() {
            this.loading = true
            try {
                const { data } = await this.api.getAppointmentPrintables(this.clinic.id)
                this.options = [
                    { label: 'Imprimir resumo', action: 'printDivinopolisResume', active: showFeature('Resumo do agendamento') },
                    { label: 'Imprimir agendamento - A4', action: 'printAppointmentDocumentA4', active: true},
                    { label: 'Imprimir agendamento - A5', action: 'printAppointmentDocumentA5', active: true},
                    { label: 'Declaração de ciência da realização de procedimento/exame', action: 'print_statement_of_awareness', active: data.print_statement_of_awareness },
                    { label: 'Pulseira de identificação', action: 'printBracelet', active: this.patientIdSettingsActive },
                ]
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        async dispatchAction(option) {
            switch (option.action) {
                case 'print_statement_of_awareness':
                    this.$bvModal.show('statement-of-awareness')
                    break;
                case 'printDivinopolisResume':
                    await this.printDivinopolisResume()
                    break;
                case 'printAppointmentDocumentA4':
                    await this.printAppointmentDocument('A4')
                    break;
                case 'printAppointmentDocumentA5':
                    await this.printAppointmentDocument('A5')
                    break;
                case 'printBracelet':
                    await this.printPatientId('bracelet')
                    break;
                default:
                    break;
            }
        },
        async printDivinopolisResume() {
            this.loading = true
            try {
                const { data } = await this.api.printAppointmentResume(this.form.id)
                saveAs(new Blob([data], { type: 'application/pdf;charset=utf-8' }), `${this.form?.patient?.name} - ${this.moment().format('DD-MM-YYYY[-]HH:mm')}.pdf`)
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.loading = false
            }
        },
        async printAppointmentDocument(style) {
            this.printingResume = true
            try {
                const { data } = await this.api.printAppointmentDocument(this.form.id, style)
                saveAs(new Blob([data], { type: 'application/pdf;charset=utf-8' }), `${this.form?.patient?.name} - ${this.moment().format('DD-MM-YYYY[-]HH:mm')}.pdf`)
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.printingResume = false
            }
        },
        async printPatientId(type){
            this.$emit('openPatientIdPrintModal');
        }
    }
}
</script>
<style lang="scss" scoped>
.icon {
    width: 24px;
    height: 24px;
}
.item-li {
    color: var(--type-active);
    font-size: 16px;
}
.printable-button{
  border: none;
  border-radius: 6px;
  /* padding: 6px 2px; */
}
.stroke {
  height: 23px !important;
  margin-left: 4px;
}

</style>